/*================================================================================
	Item Name: LSM - LittleStellaMaris
	Version: 2.0
	Author: SOMSTACK
	Author URL: 
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import "../../@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
.table {
  color: #616164;
}
.table-hover tbody tr:hover {
  color: #38383a;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 2px 2px grey;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:$primary;
}

.form-group {
  margin-bottom: 0.2rem;
}

.body-text-color {
  color: $body-color !important;
}
.body-text-color label {
  color: $body-color !important;
}

.bs-stepper-content label {
  color: #fff;
  font-size: 0.9rem !important;
}
.bs-stepper-content h5 {
  color: #fff;
}
.nav-fill > .nav-link, .nav-fill .nav-item{
 background-color: $primarywhite;
 border: 1px solid $secondarycolor;
}
.nav-tabs .nav-link{
  padding: 0.31rem 1.2rem;
}
.nav-tabs .nav-link.active{
  background-color: $primary;
  color: $primarywhite;
}
.bg-correct-Color{
  background-color: $correct;
}
.bg-danger-Color{
  background-color: $danger;
}
.bg-organge-Color{
  background-color: $orange;
}
.bg-red-Color{
  color: $orange;
  //  color : $red;
}
.clientName-nav {
  padding-top: 10px !important;
  padding: 2px;
}
.pageName-nav {
  padding-top: 10px;
}

.react-dataTable
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol
  .rdt_TableCol_Sortable {
  color: #fff !important;
}
.react-dataTable .rdt_TableHead .rdt_TableHeadRow {
  background-color: $thirdcolor !important;
}

.table th,
.table td {
  padding: 0.6rem 0.5rem;
  max-width:96px;
}
.row.react-bootstrap-table-pagination {
  margin: 10px;
}


.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: $thirdcolor;
  color: #fff;
}


.DocumentTable td, .DocumentTable td li a {
  color: #fff;
}
.labelwhite{
  color:white;
}
.labelwhite > label{
  color:white;
}

.row-divider-line {
  border-top: 3px solid $gray-200;
}
.css-1rke6ms-control {
  background-color: $disableColor;
  border: none;
}
.PublicLeafCard {
  height: auto;
  width: -webkit-fill-available !important;
  background-color: $thirdcolor;
}
.PublicLeafCardFeedback {
  background-color: $body-bg !important;
}

.PublicLeafCardHistory {
  max-height: 250px;
  overflow-y: auto;
  width: -webkit-fill-available !important;
}

 .PublicCardRow {
  height: auto;

}
.PulicleafFont{
  color: $white !important;
  text-align: right;
}
.PublicLeafCard .form-control:disabled, .PublicLeafCard  .form-control[readonly]{
  color: $black;
}
.PublicLeafCard h3{
  color: $white !important;
}

.PublicLeafCardHistory {
  background-color: $gray-700;
  color: $white;
  padding: 1px;
  max-height: 250px;
  overflow-y: auto;
}
.PublicLeafCardHistory th{
  background-color: $gray-100;
  color: $primary;
  padding: 1px;
}
.PublicLeafMarginTop{
  margin-top: 35px;
}
.labelColor {
  background-color: $primary;
  color: $white !important;
}
.PublicLeafCard > .card-body{
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .text-right {
    text-align: left !important;
  }
  .TabSectioncolumn{
    max-width: 30%;
  }
  .ComponentCard{
    height:283 !important;
  }
  .PublicCardRow {
    height: auto;
  }

  .PublicLeafCard {
    height: auto;
    width: -webkit-fill-available !important;
  }
  .PublicLeafCardHistory {
    max-height: 250px;
    overflow-y: auto;
    width: -webkit-fill-available !important;
  }
  .PulicleafFont{
    color: $white !important;
    text-align: left;
  }

  .DashboardHeaderSpan {
    width: 100%;
  }
  .AssetRow {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    display: flex;
  }
  .AssetRow > .AssetInnerRow {
    border: 1px solid #949090;
    margin: 1px !important;
    padding: 2px !important;
    width: 100% !important;
    display: flex;
  }
  .AssetInnerRow.row > .col-lg-12,.AssetInnerRow.row > .col-lg-2,.AssetInnerRow.row > .col-lg-3, .AssetInnerRow.row > .col-lg-4, .AssetInnerRow.row > .col-lg-8, .AssetInnerRow.row > .col-lg-9 {
    padding: 1px;
  }
  .AssetCardRow {
    padding: 0px;
    margin: 0px !important;
    background-color: $thirdcolor !important;
  }
  .AssetCardRow > .AssetPublicSection{
    height: 503px !important;
    margin: 2px !important;
    padding: 0px;
    // height: 503px !important;
    border-radius: 5px;
    background-color: $menucolor !important;
  }
  .AssetCardRow > .AssetCard {
    margin: 2px !important;
    padding: 0px;
    height: 503px !important;
    border-radius: 5px;
    background-color: $menucolor !important;
  }
  .AssetCardRow > .ServiceTicketCard {
    height: 500px !important;
  }
  
  .AddAssetCardRow {
    padding: 0px;
    margin: 0px !important;
    background-color: $secondary !important;
  }

  .AddAssetCardRow > .AssetCard {
    margin: 2px !important;
    padding: 0px;
    height: 265px !important;
    border-radius: 5px;
    background-color: $menucolor !important;
  }
  .AddAssetCardRow .AssetImageCard {
    padding: 5px;
    margin: 5px;
    // height: 180px !important;
    background-color: white;
    border-radius: 5px;
    text-align: center;
  }
  
  .AddAssetCardRow .WizardRow {
    padding: 5px !important;
  }


  .AssetCardRow .AssetImageCard {
    padding: 3px;
    margin: 5px;
    height: 54% !important;
    background-color: white;
    border-radius: 5px;
    text-align: center;
  }
  .AssetCard .AssetImageCardSlide {
    padding: 5px 5px 1px 5px;
    height: 197px !important;
    margin: 8px 5px 5px 5px !important;
    background-color: white;
    border-radius: 5px !important;
  }
  .AssetCard .AssetCardQRCode {
    padding: 5px 3px 1px 5px;
    height: 41% !important;
    width: 98% !important;
    margin: 5px 3px 5px 5px !important;
    background-color: #fff;
    border-radius: 5px !important;
  }
  .AssetCard .AssetImageCardQRCode {
    width: 100% !important;
    margin-bottom: 3px !important;
  }

  .AssetCardRow .WizardRow {
    padding: 5px !important;
  }

  .WizardCard {
    margin: -1px -13px -1px -13px !important;
  }

  .TabConent {
    border: transparent !important;
    height: 153px !important;
    width: 100%;
  }
  .TabConent > .TabCardImg {
    height: 178px !important;
    width: 100% !important;
  }
  .TabConent > .TabCardImg {
    height: 178px !important;
  }
  .AssetScrollBar {
    overflow: auto;
    // padding: 0px 0px 106px 1px;
    // margin: 0px -17px 0px -13px !important;
  }
  .AssetTableContainer {
    width: 100%;
    height: 50px !important;
  }
  .WizardCardOne {
    // height: 160px !important;
    margin: -1px -13px -2px -13px !important;
  }

  .AssetPublicCard {
    height: 342px !important;
    color: $white;
  }
  .AssetPublicCard > .AssetPublicCardRow {
    height: 347px !important ;
  }
  
  .AssetCard .AssetCardlabel {
    padding: 5px 3px 1px 5px;
    height: 393px !important;
    margin: 5px 3px 5px 5px !important;
    background-color: #fff;
    border-radius: 5px !important;
    display: flex !important;
  }
  .label-btn {
    width: 100% !important;
    // height: 50% !important;
    color: $primarytext !important;
    background-color: $primary !important;
    border-color: $primary !important;
  }
  :active.label {
    background-color: $primary !important;
  }
  .label-btn:hover {
    width: 100% !important;
    // height: 50% !important;
    color: $primarytext !important;
    background-color: $primary !important;
    border-color: $primary !important;
  }
  .danger-btn {
    width: 100% !important;
    // height: 50% !important;
    color: $primarytext !important;
    background-color: $red !important;
    border-color: $red !important;
  }

  .AssetsRow {
    flex-wrap: nowrap !important;
    overflow: auto;
    margin-bottom: 0.5rem;
  }
  .ServiceTickt1{
    max-width: 90%;
    margin-top: 0.5rem;
  }
  .ServiceTickt2{
    max-width: 2%;
  }
  .thirdColor{
    padding-left: 4px;
    padding-right: 4px;
    
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .float-mobile-not-right{
    float: right;
  }
  .prl-0{
    padding-right: 0;
  }
  .pll-0{
    padding-left: 0;
  }
  .TabSectioncolumn{
    max-width: 30%;
  }
  .DashboardRow {
    flex-wrap: nowrap !important;
    overflow: auto;
    margin-bottom: 0.5rem;
  }
  .DashboardRow > .col-lg-3 {
    max-width: 24%;
    margin-top: 0.5rem;
  }
  .DashboardRow > .col-lg-4 {
    max-width: 31.33333%;
    margin-top: 0.5rem;
  }
  .DashboardRow > .col-lg-6 {
    max-width: 46%;
    margin-top: 0.5rem;
  }
  .DashboardRow > .col-lg-2 {
    max-width: 16.35%;
    margin-top: 0.5rem;
  }

  .DashboardHeaderSpan {
    writing-mode: tb-rl;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    white-space: nowrap;
    max-width: 100%;
  }
  .AssetRow {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    display: flex;
  }
  .AssetRow > .AssetInnerRow {
    border: 1px solid #949090;
    margin: 1px !important;
    padding: 2px !important;
    width: 100% !important;
    display: flex;
  }
  .AssetInnerRow.row > .col-lg-12,.AssetInnerRow.row > .col-lg-2,.AssetInnerRow.row > .col-lg-3, .AssetInnerRow.row > .col-lg-4, .AssetInnerRow.row > .col-lg-8, .AssetInnerRow.row > .col-lg-9 {
    padding: 1px;
  }
  
  .AssetCardRow {
    padding: 0px;
    margin: 0px !important;
    background-color: $thirdcolor !important;
  }
  
  .AssetCardRow > .AssetCard {
    margin: 2px !important;
    padding: 0px;
    height: 455px !important;
    border-radius: 5px;
    background-color: $menucolor !important;
  }
  .AssetCardRow > .ServiceTicketCard {
    height: 420px !important;
  }
  
  .AssetCardRow .AssetImageCard {
    padding: 3px;
    margin: 5px;
    height: 180px !important;
    background-color: white;
    border-radius: 5px;
    text-align: center;
  }
  .AssetCard .AssetImageCardSlide {
    padding: 5px 5px 1px 5px;
    height: 197px !important;
    margin: 8px 5px 5px 5px !important;
    background-color: white;
    border-radius: 5px !important;
  }
  .AssetCard .AssetCardQRCode {
    padding: 5px 3px 1px 5px;
    height: 393px !important;
    width: 96% !important;
    margin: 5px 3px 5px 5px !important;
    background-color: #fff;
    border-radius: 5px !important;
  }
  .AssetCard .AssetImageCardQRCode {
    width: 100% !important;
    margin-bottom: 3px !important;
  }

  .AssetCardRow .WizardRow {
    padding: 5px !important;
  }

  .AddAssetCardRow {
    padding: 0px;
    margin: 0px !important;
    background-color: $secondary !important;
  }
  
  .AddAssetCardRow > .AssetCard {
    margin: 2px !important;
    padding: 0px;
    height: 265px !important;
    border-radius: 5px;
    background-color: $menucolor !important;
  }
  .AddAssetCardRow .AssetImageCard {
    padding: 5px;
    margin: 5px;
    // height: 180px !important;
    background-color: white;
    border-radius: 5px;
    text-align: center;
  }
  
  .AddAssetCardRow .WizardRow {
    padding: 5px !important;
  }

  .WizardCard {
    margin: -1px -13px -1px -13px !important;
  }

  .TabConent {
    border: transparent !important;
    height: 153px !important;
    width: 100%;
  }
  .TabConent > .TabCardImg {
    height: 178px !important;
    width: 100% !important;
  }
  .TabConent > .TabCardImg {
    height: 178px !important;
  }
  .AssetScrollBar {
    overflow: auto;
    // padding: 0px 0px 106px 1px;
    // margin: 0px -17px 0px -13px !important;
  }
  .AssetTableContainer {
    width: 100%;
    height: 50px !important;
  }
  .WizardCardOne {
    // height: 160px !important;
    margin: -1px -13px -2px -13px !important;
  }

  .AssetPublicCard {
    height: 342px !important;
    color: $white;
  }
  .AssetPublicCard > .AssetPublicCardRow {
    height: 347px !important ;
    height: 342px !important;
  }
  .AssetCard .AssetCardlabel {
    padding: 5px 3px 1px 5px;
    height: 393px !important;
    margin: 5px 3px 5px 5px !important;
    background-color: #fff;
    border-radius: 5px !important;
    display: flex !important;
  }
  .label-btn {
    width: 100% !important;
    // height: 50% !important;
    color: $primarytext !important;
    background-color: $primary !important;
    border-color: $primary !important;
  }
  :active.label {
    background-color: $primary !important;
  }
  .label-btn:hover {
    width: 100% !important;
    // height: 50% !important;
    color: $primarytext !important;
    background-color: $primary !important;
    border-color: $primary !important;
  }
  .danger-btn {
    width: 100% !important;
    // height: 50% !important;
    color: $primarytext !important;
    background-color: $red !important;
    border-color: $red !important;
  }
  .AssetsRow {
    flex-wrap: nowrap !important;
    overflow: auto;
    margin-bottom: 0.5rem;
  }
  .ServiceTickt1{
    max-width: 90%;
    margin-top: 0.5rem;
  }
  .ServiceTickt2{
    max-width: 2%;
  }
  .ComponentCard{
    padding: 0px;
    margin: 0px !important;
    background-color: $secondary !important;
  }
  .ComponentCardRow {
    padding: 0px;
    margin: 0px !important;
    background-color: $secondary !important;
  }
  .ComponentCardRow > .ComponentCard {
    margin: 2px !important;
    padding: 0px;
    height: 410px !important;
    border-radius: 5px;
    background-color: $menucolor !important;
  }
 
  #Contact{
    background-color: transparent;
    // border: 1px solid $menucolor ;
  }
 .ContactRow{
    background-color: $menucolor;
   
  } 
  .contactContent{
    margin:0.5rem;
    color: white;
  }
   .ContactCard{
    margin: 0px !important;
    padding: 0px;
    height: 250px !important;
    border: 2px solid $menucolor;
    background-color: transparent !important;   
  }
  .ContactUser{
    text-align: center;
    margin: 1px;
  }
  .ContactUserInner{
    margin: 1px;
   border: 1px solid $menucolor;
   height:100px;
   border-radius: 5px !important;
   background-color: transparent;
  }
  .ContactImg{
    height: 300px;
    width:100%;
  }

  
}
@media only screen and (min-width: 1201px) {
  .float-mobile-not-right{
    float: right;
  }
  .prl-0{
    padding-right: 0;
  }
  .pll-0{
    padding-left: 0;
  }
  #Contact{
    background-color: transparent;
    // border: 1px solid $menucolor ;
  }
 .ContactRow{
    background-color: $menucolor;
   
  } 
  .contactContent{
    margin:0.5rem;
    color: white;
  }
   .ContactCard{
    margin: 0px !important;
    padding: 0px;
    height: 250px !important;
    border: 2px solid $menucolor;
    background-color: transparent !important;   
  }
  .ContactUser{
    text-align: center;
    margin: 1px;
  }
  .ContactUserInner{
    margin: 1px;
   border: 1px solid $menucolor;
   height:100px;
   border-radius: 5px !important;
   background-color: transparent;
  }
  .ContactImg{
    height: 300px;
    width:100%;
  }

  .DashboardRow {
    flex-wrap: nowrap !important;
    overflow: auto;
    margin-bottom: 0.5rem;
  }
  .DashboardRow > .col-lg-3 {
    max-width: 24%;
    margin-top: 0.5rem;
  }
  .DashboardRow > .col-lg-4 {
    max-width: 31.33333%;
    margin-top: 0.5rem;
  }
  .DashboardRow > .col-lg-6 {
    max-width: 46%;
    margin-top: 0.5rem;
  }
  .DashboardRow > .col-lg-2 {
    max-width: 16.35%;
    margin-top: 0.5rem;
  }
  .DashboardHeaderSpan {
    writing-mode: tb-rl;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    white-space: nowrap;
    max-width: 100%;
  }

  .AssetInnerRow.row > .col-lg-12,.AssetInnerRow.row > .col-lg-2,.AssetInnerRow.row > .col-lg-3, .AssetInnerRow.row > .col-lg-4, .AssetInnerRow.row > .col-lg-8, .AssetInnerRow.row > .col-lg-9 {
    padding: 1px;
  }
  .AssetRow {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    display: flex;
  }
  .AssetRow > .AssetInnerRow {
    border: 1px solid #949090;
    margin: 1px !important;
    padding: 2px !important;
    width: 100% !important;
    display: flex;
  }
  .AssetCardRow {
    padding: 0px;
    margin: 0px !important;
    background-color: $thirdcolor !important;
  }
  
  .AssetCardRow > .AssetCard {
    margin: 2px !important;
    padding: 0px;
    height: 455px !important;
    border-radius: 5px;
    background-color: $menucolor !important;
  }
  .AssetCardRow > .ServiceTicketCard {
    height: 420px !important;
  }
  .AssetCardRow .AssetImageCard {
    padding: 3px;
    margin: 5px;
    // height: 180px !important;
    background-color: white;
    border-radius: 5px;
  }
  .AssetCard .AssetImageCardSlide {
    padding: 5px 5px 1px 5px;
    height: 197px !important;
    margin: 8px 5px 5px 5px !important;
    background-color: white;
    border-radius: 5px !important;
  }
  .AssetCard .AssetCardQRCode {
    padding: 5px 3px 1px 5px;
    // height: 393px !important;
    width: 96% !important;
    margin: 5px 3px 5px 5px !important;
    background-color: #fff;
    border-radius: 5px !important;
  }
  .AssetCard .AssetImageCardQRCode {
    // height: 393px !important;
    width: 100% !important;
    margin-bottom: 3px !important;
  }

  .AssetCardRow .WizardRow {
    padding: 5px !important;
  }

  .AddAssetCardRow {
    padding: 0px;
    margin: 0px !important;
    background-color: $secondary !important;
  }
  
  .AddAssetCardRow > .AssetCard {
    margin: 2px !important;
    padding: 0px;
    height: 265px !important;
    border-radius: 5px;
    background-color: $menucolor !important;
  }
  .AddAssetCardRow .AssetImageCard {
    padding: 5px;
    margin: 5px;
    // height: 180px !important;
    background-color: white;
    border-radius: 5px;
    text-align: center;
  }
  
  .AddAssetCardRow .WizardRow {
    padding: 5px !important;
  }

  .WizardCard {
    margin: -1px -13px -1px -13px !important;
    // background-color: $menucolor !important;
    // background-color: pink !important;
  }

  .TabConent {
    border: transparent !important;
    // height: 153px !important;
    width: 100%;
    // background-color: blue !important;
  }
  .TabConent > .TabCardImg {
    // height: 178px !important;
    width: 100% !important;
    // background-color: pink !important;
  }
  // .TabConent > .TabCardImg {
  //   height: 178px !important;
  //   background-color: pink !important;
  // }
  .AssetScrollBar {
    overflow: auto;
    // background-color: blueviolet;
    // padding: 0px 0px 106px 1px;
    // margin: 0px -17px 0px -13px !important;
  }
  .AssetTableContainer {
    width: 100%;
    height: 50px !important;
  }
  .WizardCardOne {
    // height: 160px !important;
    margin: -1px -13px -2px -13px !important;
  }

  .AssetPublicCard {
    height: 342px !important;
    color: $white;
  }
  .AssetPublicCard > .AssetPublicCardRow {
    height: 347px !important ;
  }
  .AssetCard .AssetCardlabel {
    padding: 5px 3px 1px 5px;
    height: 393px !important;
    margin: 5px 3px 5px 5px !important;
    background-color: #fff;
    border-radius: 5px !important;
    display: flex !important;
  }
  .label-btn {
    width: 100% !important;
    // height: 50% !important;
    color: $primarytext !important;
    background-color: $primary !important;
    border-color: $primary !important;
  }
  :active.label {
    background-color: $primary !important;
  }
  .label-btn:hover {
    width: 100% !important;
    // height: 50% !important;
    color: $primarytext !important;
    background-color: $primary !important;
    border-color: $primary !important;
  }
  .danger-btn {
    width: 100% !important;
    // height: 50% !important;
    color: $primarytext !important;
    background-color: $red !important;
    border-color: $red !important;
  }
  .AssetsRow {
    flex-wrap: nowrap !important;
    overflow: auto;
    margin-bottom: 0.5rem;
  }
  .ServiceTickt1{
    max-width: 89%;
    margin-top: 0.5rem;
  }
  .ServiceTickt2{
    max-width: 2%;
  }
  .NewServiceTicktBtn{
    padding:15px;
    
  }
  .ServiceTicketSection{
    max-height:330px;
    margin:5px;
  }
  .ComponentCardRow{
    padding: 0px;
    margin: 0px !important;
    background-color: $menucolor !important;
    // background-color: $secondary !important;
  }
   .ComponentCard{
    margin: 2px !important;
    padding: 0px;
    height: 310px !important;
    border-radius: 5px;
    // position: relative;
    // background-color:white;
   
  }
  .ComponentCard .ComponentCardQRCode {
    padding: 5px 3px 1px 5px;
    // height: 393px !important;
    width: 96% !important;
    margin: 5px 3px 5px 5px !important;
    // background-color: #fff;
    border-radius: 5px !important;
  }
  .ComponentSerial {
    margin: 2px;
    // background-color:white;
    // background: yellow;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
  .AddComponentCardRow {
    padding: 0px;
    margin: 0px !important;
    background-color: $secondary !important;
  }
  
  .AddComponentCardRow > .ComponentCard {
    margin: 2px !important;
    padding: 0px;
    height: 265px !important;
    border-radius: 5px;
    background-color: $menucolor !important;
  }
  .AddComponentCardRow .ComponentImageCard {
    padding: 5px;
    margin: 5px;
    // height: 180px !important;
    background-color: white;
    border-radius: 5px;
    text-align: center;
  }
  .table-btn{
    padding: 0.486rem 0.4rem;
    margin-top: 5px;
    margin-left: 2px;
    width: 60px !important;
  }
  .table-btn:hover{
    width: 60px !important;
  }
  
}

.card.DashboardCard {
  min-height: 160px;
  max-height: 160px;
  border-radius: 5px;
  border: 2px solid $primary;
  margin-bottom: 10px;
  background-color: $primary;
}
.DashboardCard > .card-body {
  background-color: $primary;
  padding: 0px 5px !important;
  overflow: auto;
  max-height: 134px;
  color: $primarytext;
}

.DashboardCard > .dashboard-card-value {
  font-size: 12px;
  text-align: center;
  border: 2px solid $primary;
  margin: 0px;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: $fourthcolor;
}

.DashboardCard a {
  color: $primarytext;
  font-weight: 600;
}
.DashboardCard h3 {
  color: $primarytext;
}

.DashboardHeaderSpan {
  text-align: center;
  background-color: $body-color;
  color: $primarytext;
}

.DashboardRow {
  border: 1px solid $body-color;
  border-radius: 5px;
  margin-right: 0.5px;
  margin-left: 0.5px;
}

.btn-default {
  color: $primarytext !important;
  background-color: $primary !important;
  border-color: $primary !important;
}

.AvRadio > .form-control {
  background-color: transparent;
  padding-top: 4px !important;
}

.blackColor {
  background-color: $black;
  color: $white;
}

.primaryColor {
  background-color: $primary;
  color: $primarytext;
}

.secondaryColor {
  background-color: $secondarycolor;
  color: $secondarytext;
}
.thirdColor {
  background-color: $thirdcolor;
  // color: $thirdtext;
}
label.thirdColor {
  color: $thirdtext;
}
.fivthColor label {
  color: $fourthtext;
}


.fourthColor {
  background-color: $fourthcolor;
  color: $fourthtext;
}
.fivthColor {
  background-color: $secondary;
  color: $fourthtext;
}
.smallLabelfontSize {
  font-size: 8px;
}
.LabelfontSize {
  font-size: 10px;
}
.horizontal-wizard .bs-stepper.wizard-modern .bs-stepper-content .content {
  padding: 0rem;
}
.bs-stepper.wizard-modern .bs-stepper-content {
  padding: 0rem !important;
}
.bs-stepper .bs-stepper-content .content {
  background-color: $thirdcolor !important;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  box-shadow: $box-shadow;
  // margin-bottom: 0;
  // padding-bottom: 0;
}
.WizardRow .bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-title{
  color: $primarywhite;
}

.thirdColor > h1, .thirdColor > h2, .thirdColor > h3, .thirdColor > h4, .thirdColor > h5, .thirdColor > h6, .thirdColor > label {
	color: $fourthtext;
}

// .tab-content {
//   border: 2px solid $headings-color;
//   padding: 0.1em;
// }
// .asset-card-content {
//   height: 384px;

// }
// .asset-card-small-content {
//   height: 252px;
// }
// .asset-card-smallcontent {
//   height: 121px;
// }
// .react-slider__picture {
//   min-height: 106px !important;
// }
// .asset-card-img-content {
//   height: 340px;
// }
// .asset-card-slider-height {
//   margin: 0.6em;
//   height: 650px;
// }
// .asset-card-content-bg {
//   background-color: $menucolor !important;
//   border-radius: 0.5rem;
//   // margin: 1px;
// }

// .react-slider__imgZoom {
//   top: -144px !important;
// }
.react-slick * {
  min-height: 0;
  min-width: 0;
}

.react-slick .slick-prev, .react-slick .slick-next {
  background-color: rgb(187, 184, 184);
  border-radius: 10px;
}

.react-slick .fluid__instructions {
  margin-top: 30px;
}

@media (min-width: 480px) {
  .react-slick .fluid__image-container{
    margin: 40px;
  }

  .react-slick .fluid__instructions {
    margin: 0 20px;
    padding-top: 20px;
  }
}
@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    width: 0.7in;
    left: 0;
    top: 0;
  }
  #print-section, #print-section * {
    visibility: visible;
  }
  .AssetRow {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    display: flex;
  }
  .AssetRow > .AssetInnerRow {
    border: 1px solid #949090;
    margin: 1px !important;
    padding: 2px !important;
    width: 100% !important;
    display: flex;
  }
  .AssetInnerRow.row > .col-lg-12,.AssetInnerRow.row > .col-lg-2,.AssetInnerRow.row > .col-lg-3, .AssetInnerRow.row > .col-lg-4, .AssetInnerRow.row > .col-lg-8, .AssetInnerRow.row > .col-lg-9 {
    padding: 1px;
  }
  .AssetCardRow {
    padding: 0px;
    margin: 0px !important;
    background-color: $thirdcolor !important;
  }
  .no-print{
    visibility: hidden !important;
    display: none !important;
  }
  .slick-track{
    width: 100% !important;
  }
  .DocumentTable td, .DocumentTable th {
    background-color: $thirdcolor !important;
}
} 
.slick-dots li button:before{
  color: $primary !important;
  opacity: 1 !important;
}
.slick-dots li.slick-active button:before{
  color: $fourthcolor !important;
  opacity: 1;
}
.WizardRow .bs-stepper .bs-stepper-header .step {
  padding: 0.3rem 0.4rem;
  font-size: 12px;
}

.AssetPublicCard label, h3 {
  color: $white !important;
}
.AssetPublicCard .form-control:disabled, .AssetPublicCard .form-control[readonly]{
  color: $black;
}
.InactiveCls{
  background-color: red;
  padding: 0.1rem;
  color: $white;
  border-radius: 5px;
}
.InactiveRepairCls{
  background-color: orange;
  padding: 0.1rem;
  color: $white;
  border-radius: 5px;
}
.ActiveCls{
  background-color: green;
  color: $white;
  border-radius: 5px;
}
.secondaryColor a{
  color: $black;
  font-size: 12px;
}
.idle{
  background-color: #cbc6ff !important;
  color: $primary; 
}
.inuse{
  background-color: #ffdde2 !important; 
  color: $primary
}
.AssetStateCard {
  padding: 5px;
  margin: 5px;
  // height: 180px !important;
  border-radius: 5px;
}
td > div > p > span.idle{
  background-color: #cbc6ff !important;
  color: $primary; 
}
td > div > p > span.inuse{
  background-color: #ffdde2 !important; 
  color: $primary
}
.portal > div{
  // overflow: unset !important;
  z-index: 10000;
  position: absolute;
  top: 0;
  left:250px;
}

.portal1 > div{
  // overflow: unset !important;
  z-index: 10000;
  position: absolute;
  top: -125px;
  left:250px;
}
.assetEnlargedImage{
  border: 2px solid #000;
}
.select__menu{
  color: $body-color !important;
}
.bs-stepper .bs-stepper-content .content .alert-danger {
  background-color: #eabfbf !important;
}

.slick-dots li{
  margin: 0 1px !important;
}

.custom-btn-small{
  padding: 0.486rem 0.5rem;
  margin-bottom: 0;
}
.white{
  color: $white;
}
.header-padding {
  padding: 10px;
}

.serviceTicketFeedbackStatusTab p span{
  font-size: 9pt; font-family: Verdana; color: rgb(74, 252, 254); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;
}
.clientDetail .avatar img{
  border-radius: 10%;
  cursor: default;
}

.table-hover tbody tr {
  cursor: default;
}
.clientDetail .avatar {
  border-radius: 10%;
  background-color: $white;
  cursor: default;
}
.card-action{
  background-color: $secondarycolor;
}
.service-ticket-modal{
  max-width: 80%;
}
.modal-80{
  max-width: 80%;
}

.ServiceTicketCardRow label {
  color:#fff
}
.ServiceTicketCardRow h5 {
  color:#fff
}
.ServiceTicketCardRow{
  overflow: auto;
}
.ServiceTicketSection .form-group{
  margin-bottom: 0rem;
}
.ServiceTicketSection .form-control{
  height: 2.3rem;
  border-radius: 0.257rem;
}
.badge{
    font-size: 0.9rem;
    color: #370179;
    background-color: #f4f5f5;
    width: 80px;
    text-align: left;
}
.padding-5{
  padding: 5px;
  font-size: 10px;
}
.st_In-Process .form-label {
  color: #212124;
}
.st_Initiated .form-label {
  color: #212124;
}
.st_Resolved .form-label {
  color: #212124;
}
.st_Closed .form-label {
  color: #fff;
}
.st_Open .form-label {
  color: #fff;
}
.st_In-Process label {
  color: #212124;
}
.st_Initiated label {
  color: #212124;
}
.st_Resolved label {
  color: #212124;
}
.st_Closed label {
  color: #fff;
}
.st_Open label {
  color: #fff;
}

.st_Open{
  background-color: #ff0000 !important;
  color: #fff !important;
}
.st_Initiated{
  background-color: #ff9900 !important;
  color: #212124 !important;
}
.st_In-Process{
  background-color: #f9cb9c !important;
  color: #212124 !important;
}
.st_Resolved{
  background-color: #00ff00 !important;
  color: #212124 !important;
}
.st_Closed{
  background-color: #38761d !important;
  color: #fff !important;
}  
.border-yellow{
  border: 3px solid #ff9900;
}
.border-orange{
  border: 3px solid #f9cb9c;
}
.border-darkgreen{
  border: 3px solid #00ff00;
}
.border-lightgreen{
  border: 3px solid #38761d;
}

.st_Open .custom-control-label {
  color: #fff !important;
}
.st_Initiated .custom-control-label{
  color: #212124 !important;
}
.st_In-Process .custom-control-label{
  color: #212124 !important;
}
.st_Resolved .custom-control-label{
  color: #212124 !important;
}
.st_Closed .custom-control-label{
  color: #fff !important;
}    
.AssetCard .st_Open .custom-control-label {
  margin-right: 30px;
}
.AssetCard .st_Initiated .custom-control-label{
  margin-right: 30px;
}
.AssetCard .st_In-Process .custom-control-label{
  margin-right: 30px;
}
.AssetCard .st_Resolved .custom-control-label{
  margin-right: 30px;
}
.AssetCard .st_Closed .custom-control-label{
  margin-right: 30px;
}    
.st_Open .custom-control-input {
  margin-left: 10px;
}
.st_Initiated .custom-control-input{
  margin-left: 10px;
}
.st_In-Process .custom-control-input{
  margin-left: 10px;
}
.st_Resolved .custom-control-input{
  margin-left: 10px;
}
.st_Closed .custom-control-input{
  margin-left: 10px;
}    

.st_Total{
  background-color: #5e5c5c !important;
  color: #fff !important;
}    
.report-table td, .report-table th {
  font-size: 10px !important;
  padding: 0.2rem 0.4rem !important;
  background-color: $body-bg;
  border-color: $gray-600; 
}
.report-table td {
  color: $black; 
}
.report-table td div {
  color: $black; 
}

.body-bg{
  background-color: $body-bg !important;
}
.card-title{
  text-transform: uppercase;
  font-size: 1.1rem !important;
}
.mr-05, .mx-05 {
  margin-right: 0.5rem !important;
}
.ml-05, .mx-05 {
  margin-left: 0.5rem !important;
}

.p-01 {
  padding: 0.1rem !important;
}

.thirdColor input[type=file] {
 color: $white;
}
.bs-stepper-content input[type=file] {
  color: $white;
 }
.full-width{
  width: 100%;
}
.AvCheckbox > .form-control {
  background-color: transparent;
}
.report-footer > tbody > tr:last-child > td {
  background-color: #360078 !important;
  color: #fff;
}
.report-footer .primaryColor {
  background-color: #1c92a1ff;
  color: #fff;
}
.bg-grey{
  background-color: #949090;
  color: #fff;
}
// .flatpickr-input[readonly], .flatpickr-input ~ .form-control[readonly], .flatpickr-human-friendly[readonly]{
//   background-color: $white !important;
// }
.nav-tabs {
  margin-bottom: 0rem;
}
.text-grey{
  color: $gray-700;
}
p.text-italic {
  font-style: italic;
  font-weight: 400;
}
.text-bold {
  font-weight: 600;
}
.publicSTUNASN::-webkit-input-placeholder {
  font-size: 12px;
  line-height: 1;
}
.badRating {
  color: #fff;
  background: #e16b6b !important;
}
.poorRating {
  color: #fff;
  background: #f3993e !important;
}
.okRating {
  color: #fff;
  background: #fce5cd !important;
}
.goodRating {
  color: #fff;
  background: #d9ead3 !important;
}
.greatRating {
  color: #fff;
  background: #73f340 !important;
}
.Rating{
  padding: 8px;
  margin: 0 !important;
  width: 100%;
    height: 2.714rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.45;
}
.Rating > label::after {
  margin-left: 5px !important;
  margin-top: 8px;
}
.Rating > label::before {
  margin-left: 5px !important;
  margin-top: 8px;
}
.Rating > .custom-control-label {
  width: 100%;
  color: #000;
  font-size: 1rem;
  margin-left: 0.2rem;

}

.RatingList {
  z-index: 2;
  color: #000;
}

.RatingList.active {
  z-index: 2;
  color:#000;
  background-color: inherit;
  border-color: inherit;
  font-size: 16px;
  font-weight: 700;
  padding: 0.5rem 0.2rem;
}

.RatingList.active:before {
  content: "\2714\0020";
}
.react-bootstrap-table-editing-cell{
  min-width: 200px;
}


.thirdColor .invalid-feedback {
  color: #f78b8b !important;
}
.wordBreak {
  word-break: break-word;
}
.DocumentTable td {
  word-break: break-word;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label{
 color:#d3cfcf; 
}
.lightgrey{
  color:#d3cfcf; 
  margin-bottom: 0;
 }
 .select__option--is-selected > div > .customAbbreviation {
  color: #ffff !important;
}

.ServiceTicketSection .form-control:disabled, .ServiceTicketSection .form-control[readonly], .ServiceTicketCardRow .form-control[readonly] {
    color: #212122;
    background-color: #d1cece;
    opacity: 1;
    border: 1px solid #979797;
}
.form-control{
  color: #444446;
}
.form-control:focus{
  color: #444446;
}

.select__control .select__single-value, .react-select__control .select__single-value {
  color: #444446 !important;
}
.select__control .select__placeholder, .react-select__control .select__placeholder {
  color: #444446 !important;
}

.f-1 {
  font-size: 2rem;
  padding: 6px;
  line-height: 3rem;
  text-align: center;
  margin-bottom: 0px;
}
.publicLeafCount{
  font-size: 30px;
  font-weight: 700;
}
.updateHtml > p:first-letter{
  text-transform:capitalize;
}
.updateHtml > p {
  margin-bottom: 0.1rem;
  color: #4f4f4f;
}
.updateHtml > p > a {
  font-size: 16px;
  color: #fff;
}
.updateHtml > p > span > a {
  font-size: 16px;
  color: #fff;
}
.card-title > small {
  text-transform: initial;
}

.stMenu {
  border: 1px solid #ccc;
  padding: 2px;
  border-radius: 4px;
  color: #fff;
  margin-right: 0px;
}
.stMenu > label {
  color: #fff;
  font-weight: 500;
  padding: 3px;
  // font-size: 18px !important;
}
.stMenu > label::before {
  margin: 2px 4px;

}
.pt-03 {
  padding-top: 0.3rem;
}

.select__control--is-disabled {
  background-color: #666666 !important;
  // border-color: #979797 !important;
  color: #fff !important;

}

.pl-0.col-sm-11.white {
  background-color: #fff;
  padding: 2px;
  border-radius: 2px;
}
.hr-class {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-top: 1px solid #979797;
}
.secondaryColor > .custom-control-input:disabled ~ .custom-control-label {
  color: #fff;
}
.grayTable td {
  background: #d1cece;
  color: #212122;
}
.grayColor {
  background-color: #d1cece !important;
  color: #212122;
}
.grayColor  h5{
  color: #212122 !important;
}
.ServiceTicketAssignedModalCardRow label {
  color:#fff
}
.required {
  color: #ff6363;
  padding: 8px 0px;
  text-align: left;
}
.text-danger {
  color: #ff6363 !important;
}
.text-danger > label {
  color: #ff6363 !important;
}
.public {
  padding: 8px 2px;
  font-size: 13px;
}

.inActive {
  background-color: orange;
}
.inActive label {
  color: #000;
}
.inActive .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label  {
  color: #000;
}

.inActiveCondemned label {
  color: #000;
}

.inActiveCondemned {
  background-color: red;
}
.AvRadio, .pt-0 > .form-control {
  padding: 0px !important;
}
.text-underline {
  text-decoration: underline;
}
.py-03 {
  padding: 3% 0;
}
.form-control:disabled, .form-control[readonly] {
  opacity: 1 !important;
  background-color: #666666 !important;
  color: #fff !important;
}
.custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after, .custom-radio .custom-control-label::before, .custom-radio .custom-control-label::after {
  left: 3px;
  top: 0px;
}
.py-02 {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.select__single-value.select__single-value--is-disabled {
  color: #fff !important;
}
.rowClass {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  color: #22292f;
}
#pageDropDown {
  padding: 0.486rem 1rem;
  font-size: 0.81rem;
  line-height: 1;
  border-radius: 0.358rem;
}
.dropdown-menu {
  min-width: unset;
}
.Toastify__toast--error {
    background-color: $red;
}
.Toastify__toast--error > .Toastify__toast-body  > .toastify-body > span {
    color: #fff;
}
.Toastify__close-button--error > svg {
      fill: #fff !important;
}
.Toastify__toast--success {
  background-color: $success;
}
.Toastify__toast--success > .Toastify__toast-body  > .toastify-body > span {
  color: black;
}
.Toastify__toast.Toastify__toast--success .toast-title {
  color: black !important;
  text-transform: uppercase;
}

.Toastify__close-button--success > svg {
    fill: black !important;
}
hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-top: 1px solid #ebe9f1;
}

td.reset-expansion-style {
  background-color: #d4d4d4;
}

.custom-control-inline {
  margin-right: 0.5rem;
}
@keyframes blinking {
  0% {
    color: red;
    font-weight: 700;
    font-size: 18px;
    padding-top: 0;
    padding-bottom: 0;
  }
  100% {
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.blink {
  animation: blinking 1s infinite;
}
.stTable th, .stTable td {
  padding: 0.2rem 0.5rem;
}
.padding-4{
  padding: 4px;
  font-size: 10px;
}
.red-text {
  background-color: red;
  color: #fff;
  padding: 0.2rem;
}
.green-text {
  background-color: #00ff00;
  color: #000;
  padding: 0.2rem;
}
.inner-table{
  width:60%;
  background-color: #fff;
}